<template>
  <div class="flex-v flex-align-center w100 ignore-about">
    <div class="flex-v w100 flex-align-center ignore-about-header">
      <div class="ignore-fz32 bold ignore-mt120 z0">关于我们</div>
      <div class="ignore-fz16 ignore-mt8 uppercase c-cac z0">ABOUT US</div>
      <div class="z0 ignore-color-line ignore-mt8"></div>
    </div>
    <div class="ignore-about-box">
      <div class="flex w100 flex-pack-between ignore-intro-bg">
        <img v-lazy="introPic" class="ignore-intro-pic" />
        <div class="flex-v">
          <div class="ignore-intro-right ignore-mt50">
            {{ companyDesc.articleIntro }}
          </div>
          <!-- <div class="ignore-intro-right ignore-mt20">
            近年来，针对物联网领域的蓬勃发展，充分利用自身在无线信号检测领域多年沉淀的成功经验，为农业物联网行业客户提供全方位技术解决方案和定制服务。同时，也和各地的农业科研院所、农业大学开展了广泛的交流与科研合作，聘请相关专家组建顾问团队，深度挖掘行业需求，不断优化顶层设计和提升技术创新能力，为广大客户提供一系列的信息化整体解决方案。以客户需求为导向，公司专注于智慧农业、数字农业相关软硬件产品的开发、系统集成、信息服务，为农业农村全产业链条提供标准化、信息化、智能化的整体解决方案。
          </div> -->
        </div>
      </div>
      <div class="flex w100 flex-pack-between ignore-mt80">
        <div
          class="ignore-company-item flex-v flex-align-center"
          v-for="(item, index) in companyList"
          :key="index"
        >
          <div class="ignore-white-line"></div>
          <div class="ignore-fz32 bold c-white">{{ item.title }}</div>
          <div class="ignore-fz20 c-white uppercase ignore-mt10">
            {{ item.eng }}
          </div>
          <img v-lazy="item.icon" class="ignore-company-icon" />
          <div class="ignore-company-desc w100">{{ item.desc }}</div>
        </div>
      </div>
      <div class="flex w100 ignore-mt80">
        <img class="ignore-tech-pic" v-lazy="techPic" />
      </div>
      <div class="flex w100 flex-pack-between ignore-mt60">
        <div
          class="relative ignore-tech-item"
          v-for="(item, index) in techList"
          :key="index"
          @mouseenter="item.active = true"
          @mouseleave="item.active = false"
        >
          <img
            class="animate__animated ignore-tech-item"
            v-lazy="item.bg"
            :class="
              item.active == true
                ? 'animate__fadeOut '
                : item.active == false
                ? 'animate__fadeIn'
                : ''
            "
          />
          <div
            class="animate__animated ignore-tech-item-active flex-v flex-align-center ignore-fz14"
            :class="
              item.active == true
                ? 'animate__fadeIn'
                : item.active == false
                ? 'animate__fadeOut'
                : 'more-hidden'
            "
          >
            <img v-lazy="item.icon" class="ignore-tech-icon" />
            <div class="ignore-fz20 bold">{{ item.name }}</div>
            <div class="ignore-fz16 ignore-mt10 w100">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="flex w100 flex-pack-between ignore-mt80">
        <img v-lazy="teamImg" class="ignore-team-pic" />
        <div class="flex-v ignore-team-right">
          <img v-lazy="teamPic" class="ignore-tech-pic ignore-mt50" />
          <div class="ignore-about-desc ignore-mt60">
            我们是追求创新与力求不断超越自己的团队，团队成员拥有多年的农业信息化、智能化技术背景，核心骨干历经数十个数字农业项目的历练和沉淀。我们尊重每次合作的机会与挑战，不断精进，力求完美。
          </div>
          <div class="ignore-about-desc ignore-mt20">
            团队秉承专业、专注的服务思维，不断优化产品和服务，为农业农村现代化建设添砖加瓦。热爱农业并坚信科技的力量让我们不断前进…
          </div>
        </div>
      </div>
      <div class="flex w100 flex-pack-between ignore-mt80 ignore-mb20">
        <div class="ignore-contact-left">
          <img v-lazy="contactPic" class="ignore-contact-pic" />
          <div class="flex flex-align-center w100 ignore-mb20">
            <img class="ignore-contact-icon" v-lazy="phonePic" />
            <div class="fz18">业务咨询：{{ companyInfo.telPhone }}</div>
          </div>
          <div class="flex flex-align-center w100 ignore-mb20">
            <img class="ignore-contact-icon" v-lazy="mobilePic" />
            <div class="fz18">技术服务：{{ companyInfo.mobilePhone }}</div>
          </div>
          <div class="flex flex-align-center w100 ignore-mb20">
            <img class="ignore-contact-icon" v-lazy="mailPic" />
            <div class="fz18">电子邮箱：{{ companyInfo.email }}</div>
          </div>
          <div class="flex flex-align-center w100">
            <img class="ignore-contact-icon" v-lazy="addrPic" />
            <div class="fz18">公司地址：{{ companyInfo.companyAddr }}</div>
          </div>
        </div>

        <div class="ignore-contact-right" ref="aboutMap"></div>
      </div>
    </div>
  </div>
</template>

<script>
window.addEventListener("beforeunload", function () {
  window.scrollTo(0, 0);
});
import introPic from "@/assets/about/intro.jpg";
import company1Pic from "@/assets/about/company1.png";
import company2Pic from "@/assets/about/company2.png";
import company3Pic from "@/assets/about/company3.png";
import techPic from "@/assets/about/tech.png";
import tech1Pic from "@/assets/about/tech1.png";
import tech2Pic from "@/assets/about/tech2.png";
import tech3Pic from "@/assets/about/tech3.png";
import tech4Pic from "@/assets/about/tech4.png";
import tech1Img from "@/assets/about/tech1.jpg";
import tech2Img from "@/assets/about/tech2.jpg";
import tech3Img from "@/assets/about/tech3.jpg";
import tech4Img from "@/assets/about/tech4.jpg";
import teamPic from "@/assets/about/team.png";
import teamImg from "@/assets/about/team.jpg";
import contactPic from "@/assets/about/contact.png";
import mapIcon from "@/assets/home/map-icon.png";
import phonePic from "@/assets/home/phone.png";
import mobilePic from "@/assets/home/mobile.png";
import mailPic from "@/assets/home/mail.png";
import addrPic from "@/assets/home/addr.png";
import { getHomeContact, getCompanyInfo } from "@/api/home";
export default {
  name: "about",
  data() {
    return {
      introPic,
      techPic,
      teamPic,
      teamImg,
      contactPic,
      phonePic,
      mobilePic,
      mailPic,
      addrPic,
      companyList: [
        {
          title: "公司愿景",
          eng: "vision",
          icon: company1Pic,
          desc:
            "公司秉承创新、快速、专业、诚信的工作态度，世纪锐通将致力于成为国内一流的智慧农业、数字农业解决方案提供商，为推动中国农业信息化、数据化、智能化、精准化、职业化、生态化、规模化作出自己不凡的贡献。",
        },
        {
          title: "业务范围",
          eng: "service",
          icon: company2Pic,
          desc:
            "公司业务涵盖大田、设施园艺、畜禽、水产、粮库、食用菌、冷链、环境监测、农产品溯源等系统解决方案和定制开发、信息服务、企业信息化综合解决方案等，具备农业农村全行业、全产业链的综合服务能力。",
        },
        {
          title: "公司理念",
          eng: "culture",
          icon: company3Pic,
          desc:
            "迎难不惧，逆风前行，挑战不可能已成为我们的一种习惯。一次次突破自我极限，一次次追求产品极致，我们的创新思维永无极限。世纪锐通存在的价值，是竭力为客户创造价值，最高的境界不止于纯粹的产品。",
        },
      ],
      techList: [
        {
          bg: tech1Img,
          icon: tech1Pic,
          name: "图像识别",
          desc:
            "基于计算机视觉的图片对象检测与识别技术，行业领先的通用物体检测算法，能有效检测出图片中超过200类的常见物体，广泛应用于农作物种类识别、病虫害远程智能诊断、水果品质精选分级等。",
          id: 1,
          active: -1,
        },
        {
          bg: tech2Img,
          icon: tech2Pic,
          name: "机器学习",
          desc:
            "通过机器学习模型，追踪和预测影响农作物产量的各种因素，能有效防止有缺陷的作物，并优化健康作物产量和质量，通过卫星遥感数据和AI机器学习算法来预测天气，分析评估作物是否存在病虫害及病虫害爆发时期等。",
          id: 2,
          active: -1,
        },
        {
          bg: tech3Img,
          icon: tech3Pic,
          name: "数据分析",
          desc:
            "数据分析是每一个智能农业解决方案的核心。将收集到的数据转化为有意义的操作，实现精准农业生产，并应用预测算法和机器学习，基于收集的数据获得可操作的洞察力。",
          id: 3,
          active: -1,
        },
        {
          bg: tech4Img,
          icon: tech4Pic,
          name: "数据可视化",
          desc:
            "利用物联网设备来全面监测农产品生长过程，将数据信息公开透明的展示给消费者，让消费者买的放心、吃的安心。",
          id: 4,
          active: -1,
        },
      ],
      activeTech: 1,
      companyInfo: {},
      companyDesc: {},
    };
  },
  methods: {
    initMap() {
      let map = new BMap.Map(this.$refs.aboutMap, {
        // enableMapClick: false,
        minZoom: 4,
        maxZoom: 17,
      });
      map.enableScrollWheelZoom(true); //启用滚轮缩放
      let point = new BMap.Point(104.117119, 30.627565);
      let icon = new BMap.Icon(mapIcon, new BMap.Size(18, 25), {
        anchor: new BMap.Size(14, 14),
      });
      let marker = new window.BMap.Marker(point, { icon: icon }); // 创建标注
      map.centerAndZoom(point, 20);
      map.addOverlay(marker); // 将标注添加到地图中
      //marker.enableDragging();   //可拖拽;
      var opts = {
        width: 160, // 信息窗口宽度
        height: 60, // 信息窗口高度
        title: "成都世纪锐通科技有限公司", // 信息窗口标题
        message: "成都世纪锐通科技有限公司",
      };
      var infoWindow = new BMap.InfoWindow(
        "地址：四川省成都市锦江区静居寺路20号附101号",
        opts
      ); // 创建信息窗口对象
      map.openInfoWindow(infoWindow, point); //开启信息窗口
      this.map = map;
    },
    getCopanyInfo() {
      getHomeContact().then((res) => {
        this.companyInfo = res;
      });
      getCompanyInfo().then((res) => {
        this.companyDesc = res;
      });
    },
  },
  activated() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.initMap();
    this.getCopanyInfo();
  },
};
</script>

<style lang="scss" scoped>
.ignore-about {
  background: url("../assets/about/bg.jpg") no-repeat;
  background-size: 100% 320px;
  background-color: #ffffff;
  min-height: 1000px;
  .ignore-about-box {
    width: 1500px;
    margin-top: 200px;
    margin-bottom: 20px;
    .ignore-intro-bg {
      background: url("../assets/about/intro-bg.jpg");
      background-size: 730px 314px;
      background-position: bottom left;
      background-repeat: no-repeat;
      height: 470px;
      border-top: 0.5px solid rgba(0, 0, 0, 0.06);
      padding: 0 10px;
      border-radius: 6px;
      box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.1);
    }
    .ignore-intro-pic {
      height: 100px;
      margin-top: 16px;
    }
    .ignore-intro-right {
      width: 700px;
      font-size: 16px;
      color: #666;
      text-indent: 2em;
      line-height: 1.8em;
    }
    .ignore-company-item {
      width: 346px;
      padding: 30px;
      background: linear-gradient(180deg, #91c23b 0%, #399b65 100%);
      border-radius: 6px;
      box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.2);
      .ignore-white-line {
        width: 100px;
        height: 4px;
        margin-top: 5px;
        margin-bottom: 40px;
        background: white;
      }
      .ignore-company-icon {
        width: 100px;
        height: 100px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .ignore-company-desc {
        font-size: 16px;
        color: #fff;
        line-height: 2em;
      }
    }
    .ignore-company-item:first-child {
      background: #91c23b;
    }
    .ignore-company-item:last-child {
      background: #399b65;
    }
    .ignore-tech-pic {
      height: 120px;
      width: 390px;
    }
    .ignore-tech-item {
      width: 240px;
      height: 360px;
      border-radius: 6px;
      .ignore-tech-item-active {
        width: 238px;
        height: 358px;
        position: absolute;
        box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.2);
        // border: 1px solid #ccc;
        left: 0;
        top: 0;
        padding: 20px;
        border-radius: 6px;
      }
      .ignore-tech-icon {
        height: 64px;
        width: 64px;
        margin-top: 12px;
        margin-bottom: 30px;
      }
      .more-hidden {
        opacity: 0;
      }
    }
    .ignore-team-pic {
      border-radius: 6px;
      width: 660px;
      height: 574px;
    }
    .ignore-team-right {
      width: 650px;
      .ignore-tech-pic {
        height: 120px;
        width: 280px;
      }
      .ignore-about-desc {
        font-size: 16px;
        color: #666;
        text-indent: 2em;
        line-height: 1.6em;
      }
    }
    .ignore-contact-pic {
      width: 345px;
      height: 120px;
      margin-bottom: 36px;
    }
    .ignore-contact-left {
      width: 680px;
      .ignore-contact-icon {
        height: 48px;
        width: 48px;
        margin-right: 21px;
      }
    }
    .ignore-contact-right {
      width: 660px;
      height: 400px;
    }
  }
}
.ignore-color-line {
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, #34b5ff, #39e274);
  border-radius: 2px;
}
.ignore-case-header {
  z-index: 2;
  width: 100%;
}

.ignore-more-btn-box {
  width: 150px;
  height: 50px;
  .ignore-more-btn {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ignore-more-btn-active {
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background: linear-gradient(
      -45deg,
      rgba(52, 181, 255, 0.83),
      rgba(57, 226, 116, 0.83)
    );
    box-shadow: 0px 18px 25px 0px rgba(57, 226, 116, 0.33);
    border: none;
    position: absolute;
    left: 0;
    top: 0;
  }
  .more-hidden {
    opacity: 0;
  }
}
</style>
<!-- <style scoped>
@media (max-width: 1366px) {
  .ignore-about {
    background-size: 100% 260px !important;
    min-height: 1060px !important;
  }
  .ignore-about-header .ignore-mt120 {
    margin-top: 100px !important;
  }
  .ignore-about-header .ignore-mt30 {
    margin-top: 12px !important;
  }
  .ignore-about-header .ignore-fz32 {
    font-size: 26px !important;
  }
  .ignore-about-box {
    width: 900px !important;
    margin-top: 120px !important;
  }
  .ignore-intro-bg {
    background-size: 550px 240px !important;
    height: 354px !important;
  }
  .ignore-intro-pic {
    height: 80px !important;
    margin-top: 16px !important;
  }
  .ignore-intro-right {
    width: 500px !important;
    font-size: 14px !important;
  }
  .ignore-intro-bg .ignore-mt50 {
    margin-top: 44px !important;
  }
  .ignore-intro-bg .ignore-mt20 {
    margin-top: 16px !important;
  }
  .ignore-company-item {
    width: 280px !important;
    padding: 20px !important;
  }
  .ignore-mt80 {
    margin-top: 60px !important;
  }
  .ignore-company-item {
    width: 280px !important;
    padding: 20px !important;
  }
  .ignore-white-line {
    width: 74px !important;
    height: 3px !important;
    margin-bottom: 28px !important;
  }
  .ignore-company-item .ignore-fz32 {
    font-size: 28px !important;
  }
  .ignore-company-item .ignore-mt10 {
    margin-top: 6px !important;
  }
  .ignore-company-item .ignore-fz20 {
    font-size: 16px !important;
  }
  .ignore-company-icon {
    width: 84px !important;
    height: 84px !important;
    margin-top: 22px !important;
    margin-bottom: 22px !important;
  }
  .ignore-company-desc {
    font-size: 12px !important;
    line-height: 1.5em !important;
  }
  .ignore-tech-pic {
    height: 102px !important;
    width: 334px !important;
  }
  .ignore-mt60 {
    margin-top: 44px !important;
  }
  .ignore-tech-item {
    width: 216px !important;
    height: 314px !important;
  }
  .ignore-tech-item-active {
    width: 216px !important;
    height: 314px !important;
    padding: 14px !important;
  }
  .ignore-tech-icon {
    height: 50px !important;
    width: 50px !important;
    margin-top: 8px !important;
    margin-bottom: 22px !important;
  }
  .ignore-tech-item .ignore-fz20 {
    font-size: 16px !important;
  }
  .ignore-tech-item .ignore-mt30 {
    margin-top: 22px !important;
  }
  .ignore-tech-item .ignore-mt30 {
    margin-top: 22px !important;
  }
  .ignore-team-pic {
    width: 432px !important;
    height: 358px !important;
  }
  .ignore-team-right {
    width: 424px !important;
  }
  .ignore-team-right .ignore-tech-pic {
    height: 98px !important;
    width: 276px !important;
  }
  .ignore-team-right .ignore-mt50 {
    margin-top: 35px !important;
  }
  .ignore-team-right .ignore-about-desc {
    font-size: 14px !important;
  }
  .ignore-team-right .ignore-mt60 {
    margin-top: 36px !important;
  }
  .ignore-team-right .ignore-mt20 {
    margin-top: 14px !important;
  }
  .ignore-contact-left {
    width: 460px !important;
  }
  .ignore-contact-pic {
    width: 340px !important;
    height: 114px !important;
    margin-bottom: 32px !important;
  }
  .ignore-contact-left .ignore-mb20 {
    margin-bottom: 16px !important;
  }
  .ignore-contact-icon {
    height: 36px !important;
    width: 36px !important;
    margin-right: 16px !important;
  }
  .ignore-contact-right {
    width: 460px !important;
    height: 328px !important;
  }
}
</style> -->